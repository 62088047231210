import { render, staticRenderFns } from "./FrontFooter.vue?vue&type=template&id=671f725e&scoped=true"
import script from "./FrontFooter.vue?vue&type=script&lang=js"
export * from "./FrontFooter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "671f725e",
  null
  
)

export default component.exports