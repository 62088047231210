import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6c538f64 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _5c10ca38 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _eb2fc0de = () => interopDefault(import('../pages/coupons/index.vue' /* webpackChunkName: "pages/coupons/index" */))
const _9d344b10 = () => interopDefault(import('../pages/edit-profile/index.vue' /* webpackChunkName: "pages/edit-profile/index" */))
const _57a368f4 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _eddb1412 = () => interopDefault(import('../pages/new-password.vue' /* webpackChunkName: "pages/new-password" */))
const _06d23c89 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _e20f2da6 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _12371db6 = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _26cd742c = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _3aed78f8 = () => interopDefault(import('../pages/stores/index.vue' /* webpackChunkName: "pages/stores/index" */))
const _a3487a7c = () => interopDefault(import('../pages/terms-and-condtions.vue' /* webpackChunkName: "pages/terms-and-condtions" */))
const _72078ec0 = () => interopDefault(import('../pages/verification-code.vue' /* webpackChunkName: "pages/verification-code" */))
const _e9a1e4c0 = () => interopDefault(import('../pages/coupons/add.vue' /* webpackChunkName: "pages/coupons/add" */))
const _a9ec2ac0 = () => interopDefault(import('../pages/coupons/most-popular.vue' /* webpackChunkName: "pages/coupons/most-popular" */))
const _2ce3d9da = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6ec31161 = () => interopDefault(import('../pages/coupons/_id/coupon-details.vue' /* webpackChunkName: "pages/coupons/_id/coupon-details" */))
const _6b9fe9d0 = () => interopDefault(import('../pages/coupons/_id/edit.vue' /* webpackChunkName: "pages/coupons/_id/edit" */))
const _234901b1 = () => interopDefault(import('../pages/messages/_id/chat.vue' /* webpackChunkName: "pages/messages/_id/chat" */))
const _095e79f3 = () => interopDefault(import('../pages/stores/_id/store-details.vue' /* webpackChunkName: "pages/stores/_id/store-details" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _6c538f64,
    name: "about"
  }, {
    path: "/contact",
    component: _5c10ca38,
    name: "contact"
  }, {
    path: "/coupons",
    component: _eb2fc0de,
    name: "coupons"
  }, {
    path: "/edit-profile",
    component: _9d344b10,
    name: "edit-profile"
  }, {
    path: "/forgot-password",
    component: _57a368f4,
    name: "forgot-password"
  }, {
    path: "/new-password",
    component: _eddb1412,
    name: "new-password"
  }, {
    path: "/privacy",
    component: _06d23c89,
    name: "privacy"
  }, {
    path: "/profile",
    component: _e20f2da6,
    name: "profile"
  }, {
    path: "/sign-in",
    component: _12371db6,
    name: "sign-in"
  }, {
    path: "/sign-up",
    component: _26cd742c,
    name: "sign-up"
  }, {
    path: "/stores",
    component: _3aed78f8,
    name: "stores"
  }, {
    path: "/terms-and-condtions",
    component: _a3487a7c,
    name: "terms-and-condtions"
  }, {
    path: "/verification-code",
    component: _72078ec0,
    name: "verification-code"
  }, {
    path: "/coupons/add",
    component: _e9a1e4c0,
    name: "coupons-add"
  }, {
    path: "/coupons/most-popular",
    component: _a9ec2ac0,
    name: "coupons-most-popular"
  }, {
    path: "/",
    component: _2ce3d9da,
    name: "index"
  }, {
    path: "/coupons/:id/coupon-details",
    component: _6ec31161,
    name: "coupons-id-coupon-details"
  }, {
    path: "/coupons/:id/edit",
    component: _6b9fe9d0,
    name: "coupons-id-edit"
  }, {
    path: "/messages/:id?/chat",
    component: _234901b1,
    name: "messages-id-chat"
  }, {
    path: "/stores/:id/store-details",
    component: _095e79f3,
    name: "stores-id-store-details"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
