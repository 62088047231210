
export default {
  name: "FrontFooter",
  data()
  {
    return {
      terms : null,
      facebook : null,
      twitter : null,
      instagram : null,
      snapchat : null,
      youtube : null,
      andriod_app_link : null,
      ios_app_link : null,
      website_google_analysis_header : null,
      website_google_analysis_footer : null,
    };
  }
  ,
  async mounted() {
    await this.getPolicy()
    $('head').append(this.website_google_analysis_header.value ?? null);
    $('body').append(this.website_google_analysis_footer.value ?? null);


  },
  methods : {
    async getPolicy()
    {
      await this.$axios.$get("/policy").then(({data})=>{
        this.facebook = data?.facebook
        this.twitter = data?.twitter
        this.instagram = data?.instagram
        this.andriod_app_link = data?.android_app_link
        this.youtube = data?.youtube
        this.snapchat = data?.snapchat
        this.ios_app_link = data?.ios_app_link
        this.website_google_analysis_header = data?.website_google_analysis_header
        this.website_google_analysis_footer = data?.website_google_analysis_footer
      })
    }
  }
}
