
export default {
  name: "FrontNavbar",
  async mounted() {
    await this.getUnread()
  },
  data()
  {
    return {
      conversations : [],
      countOfConversations : 0,
      isLoading : true,
      current_page : 1,
      hasMorePages : true,
    };
  },
  methods : {
    async logout()
    {
      await this.$auth.logout().then(function ()
      {
        location.reload()
      })
    },
    async getUnread()
    {
      if (this.$auth.loggedIn)
      {
        await this.$axios.$post("/unread-messages", {
          params : {
            page : this.current_page
          }
        }).then((data)=>{
          console.log("data" , data?.data)
          data?.data?.conversations?.forEach((val,index)=>{
            this.conversations.push(val)
          })

          this.countOfConversations = data?.data?.count
          this.current_page = data?.pagination?.current_page;
          this.hasMorePages = data?.pagination?.hasMorePages;
          this.isLoading = false
        })
      }
    },
    async getMoreData()
    {
      if (this.hasMorePages)
      {
        this.current_page++;
        await this.getUnread()
      }
    }
  }
}
