
export default ({ $axios , $router , error }) => {
  $axios.onError(err => {
    if (err?.response?.status === 401)
    {
        location.reload()
    }
    if (err?.response?.status === 404 || err?.response?.status === 500)
    {
      error({ statusCode: 404, message: 'رابط خطأ او منتهي الصلاحية.' })
    }
  });
};
